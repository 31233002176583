<template>
<b-container>
  <div class="py-5 pt-4">
    <div class="row pr-4 pl-4 mt-4">
      <div class="row">
          <h4 class="title-job">
            Company
          </h4>
          <div style="padding: 0 5px;"> 
            <span class="circle">{{ totalData }}</span> 
          </div>
      </div>
      <div class="col text-right underline">
        <a class="see-com-total" href="/companies">ดูบริษัททั้งหมด >></a>
      </div>
    </div>
    <divider />
    <div class="row">
      <div
        class="col-md-4 mb-3"
        v-for="(company, index) in companies"
        :key="index"
      >
        <div class="card h-80">
          <router-link :to="`/companies/${company._id}/jobs`">
            <div class="card-head">
              <img
                class="card-img-top"
                v-if="company && company.banners"
                :src="company && company.banners"
                :alt="company.name && company.name.th"
              />
              <img
                :class="company && company.banners ? 'card-img-logo' : 'card-img-logo-second'"
                v-if="company && company.logo"
                :src="company && company.logo"
                :alt="company.name && company.name.th"
              />
            </div>
          </router-link>
          <div class="card-body">
            <router-link :to="`/companies/${company._id}/jobs`">
              <h4 class="card-title text-bolder">{{ company.name && company.name.th }}</h4>
            </router-link>
            <p class="card-text text-limit">{{ company.descriptions && company.descriptions.th }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</b-container>
</template>

<script>
import Divider from "@/components/Divider";
import { getCompanies } from "@/services/company.service";
export default {
  components: {
    Divider,
  },
  data() {
    return {
      companies: [],
      totalData: 0,
      currentPage: 1,
      totalPage: 1,
      limit: 6
    };
  },
  mounted() {
    this.loadData({ page: 1 });
  },
  methods: {
    async loadData(payload) {
      const data = await getCompanies({ ...payload, limit: this.limit });
      this.companies = data.data;
      this.totalData = data.total;
      this.currentPage = payload.page;
      this.totalPage = data.total > 0 ? Math.ceil(data.total / this.limit) : 1
    },
  },
};
</script>
<style lang="scss" scoped>
.title-job {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.card {
  border-radius: 5px;
  box-shadow: 0px 3px 4px rgb(0 0 0 / 10%);
  .card-title {
    font-size: 1.2rem;
    color: $primary-hard-color !important;
  }
  .card-head {
    position: relative;
    .card-img-top {
      height: 120px;
    }
    .card-img-logo {
      position: absolute;
      left: 1em;
      bottom: -15px;
      width: 25%;
      box-shadow: 1px 1px 10px #888888;
      border-radius: 10px;
    }
    .card-img-logo-second {
      margin: 1em;
      width: 25%;
      box-shadow: 1px 1px 10px #888888;
    }
  }
}
a.see-com-total {
  color: #000000 !important;
}
.text-limit {
  height: 6em;
  overflow: hidden;
}
</style>
