<template>
  
<div class="h-100">
  <div class="landing-cover">
    <img src="@/assets/images/cover-v1.png" class="landing-cover-image" />
    <div class="centered">
      <div class="title">
        <p class="title-1"><span class="quote">“</span>งานที่เหมาะกับ <span class="title-main text-bolder"> คุณ</span></p>
        <p class="title-1">พนักงานที่เหมาะกับงานของ <span class="title-main text-bolder">บริษัท </span>  คุณ<span class="quote">”</span></p>
        <p class="detail">สามารถค้นหางาน และฝากเรซูเม่ เพื่อติดต่อกับบริษัทฯ</p>
      </div>
      <div class="register d-flex">
        <b-button type="submit" size="lg" variant="primary" class="button-head1" to="/createcompany">
          ประกาศรับสมัคร
        </b-button>
        <b-button type="submit" size="lg" variant="primary" class="button-head2" to="/createuser">
          สมัครงาน
        </b-button>
      </div>
    </div>
  </div>
  <!-- Section  2: Type-->
  <section class="second-section">
    <div class="container-fluid pt-5 col-lg-12">
      <div class="justify-content-md-center text-center">
        <p class="title-1 text-bolder">ประเภทธุรกิจที่หลากหลาย จากการจัดกลุ่มงานของ EzezJob</p>
        <p class="title-2 text-bolder">เข้าถึงทุกความต้องการทำงานของบุคคลทั่วไปและเฉพาะทางของสายอาชีพ</p>
      </div>
      <div class="justify-content-md-center text-center col-12 icon" style="padding: 0 6rem;">
        <div class="box col-md-3 col-sm-12">
          <img src="@/assets/svg/landing-detail1.svg" alt="งานใกล้บ้านเดินทางสะดวก">
          <p class="title-3 text-bolder">งานใกล้บ้านเดินทางสะดวก</p>
        </div>
        <div class="box col-md-3 col-sm-12">
          <img src="@/assets/svg/landing-detail2.svg"  alt="งานตรงกับความรู้ความสามารถ">
          <p class="title-3 text-bolder">งานตรงกับความรู้ความสามารถ</p>
        </div>
        <div class="box col-md-3 col-sm-12">
          <img src="@/assets/svg/landing-detail3.svg" alt="งานตรงประสบการณ์">
          <p class="title-3 text-bolder">งานตรงประสบการณ์</p>
        </div>
        <div class="box col-md-3 col-sm-12">
          <img src="@/assets/svg/landing-detail4.svg" alt="องค์กรที่มั่นคง">
          <p class="title-3 text-bolder">องค์กรที่มั่นคง</p>
        </div>
      </div>
    </div>
  </section>
  <!-- Section  3: company-->
  <section class="third-section">
    <companies>
    </companies>
  </section>
  <!-- Section  4: about-->
  <section class="forth-section">
    <b-container>
      <p class="underline text-bolder pt-5">เกี่ยวกับเรา</p>
      <br>
      <p class="text-bolder"> 
        <span class="eplatform">E-Platform</span> <br>
        ทรัพยากรมนุษย์ที่ครอบคลุม ทั้งในส่วนผู้หางานและผู้ว่าจ้าง ระบบสามารถรองรับการว่าจ้างที่หลากหลาย ไม่ว่าจะเป็น การจ้างงานแบบประจำแบบไม่ประจำ รวมไปถึงนักศึกษาฝึกงาน
      </p>
    </b-container>
  </section>

</div>
</template>

<script>
import Companies from "@/components/Companies.vue"

export default {
  components: {
    Companies
  },
  data() {
    return {
      companies: [],
      totalData: 0,
      currentPage: 1,
      totalPage: 1,
      limit: 6
    };
  },
  mounted() {
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.landing-cover {
  position: relative;
  background: white;
  max-height: 500px;
  height: 500px;
  @media (max-width: 910px) {
    height: 600px;
    max-height: 600px;
  }
}
.landing-cover-image {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  position: absolute;
  @media (max-width: 910px) {
    height: 600px;
    max-height: 600px;
  }
}
.centered {
  position: absolute;
  z-index: 100;
  top: 14rem;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 3rem;
  @media (max-width: 500px) {
    width: 100%;
    top: 17rem;
  }
  .title {
    color: white;
    text-align: center;
    span.quote {
      font-family: "Roboto";
      font-size: 2rem;
    }
    p.detail {
      margin: 2rem 0 4rem 0;
      font-size: 1.1rem;
    }
    p.title-1 {
      line-height: 3rem;
      font-size: 2rem;
    }
    span.title-main {
      line-height: 3rem;
      font-size: 3.5rem !important;
    }
  }
  .register {
    border-top: white 1px solid;
    width: 70%;
    text-align: center;
    margin: auto;
    .button-head1 {
      margin: 2rem 1rem;
      width: 100%;
      background-color: #0085D3;
      &:hover, &:focus, &:active {
        color: $secondary-color;
        background-color: $primary-color;
        border-color: $primary-color;
      }
      border-radius: 8px;
    }
    .button-head2 {
      margin: 2rem 1rem;
      width: 100%;
      background-color: white;
      color: #0085D3;
      &:hover, &:focus, &:active {
        color: $primary-hard-color;
        background-color: $secondary-color;
        border-color: $secondary-color;
      }
      border-radius: 8px;
    }
    @media (max-width: 600px) {
      display: block !important;
      .button-head1, .button-head2 {
        margin: 1rem 0 !important;
      }
    }
  }
}
.second-section {
  p {
    padding: 1rem;
  }
  p.title-1 {
    font-size: 1.7rem;
    color: #0085D3;
  }
  p.title-2 {
    font-size: 1.2rem;
    color: #000000;
  }
  .icon {
    @media (min-width: 789px) {
      display: flex;
    }
    p.title-3 {
      font-size: 1.5rem;
      color: #000000;
    }
    .box {
      width: 260px;
      margin: auto;
      padding-top: 1rem;
      img {
        width: 160px;
        height: 160px;
        padding: 1rem;
      }
    }
  }
}
.third-section {
  background: #F7F7F8;
}
.forth-section {
  .eplatform {
    color: #53A2DA;
  }
  background: #FFF;
  max-height: 500px;
  height: 500px;
}
</style>
